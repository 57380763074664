@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');
:root {
  /* SIZES */
  --header-height: 6rem;
  --block-height-M: 4rem;
  --block-height-S: 3.2rem;
  --space-S: 1rem;
  --space-M: 2rem;
  --space-L: 4rem;

  /* TYPE */
  --font-family: 'Roboto', sans-serif;
  --font-size-XS: 1rem;
  --font-size-S: 1.2rem;
  --font-size-M: 1.4rem;
  --font-size-L: 1.6rem;
  --font-size-XL: 2rem;
  --font-line-height-L: 1.8rem;
  --font-line-height-XL: 3.4rem;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  /* COLORS */
  --color-brand: rgba(22, 63, 137, 1);
  --color-brand-secondary: rgba(22, 63, 137, 0.7);
  --color-brand-highlight: #d1dee7;
  --color-brand-highlight-softer: #e6f0f2;
  --color-brand-hover: rgba(22, 63, 137, 0.9);
  --color-brand-hover-highlight: rgba(22, 63, 137, 0.3);
  --color-border: rgba(22, 63, 137, 0.25);
  --color-border-light: rgba(22, 63, 137, 0.15);
  --border: 1px solid var(--color-border);
  --border-light: 1px solid var(--color-border-light);
  --color-event-encounter: rgba(250, 233, 160, 1);
  --color-event-encounter-partially: rgba(245, 158, 132, 1);
  --color-event-encounter-unmatched: rgba(206, 44, 84, 1);
  --color-event-encounter-pending: rgba(175, 187, 202, 1);
  --color-event-loitering: rgba(207, 169, 249, 1);
  --color-event-port: rgba(153, 238, 255, 1);
  --color-event-port-highlight: rgba(153, 238, 255, 0.3);
  --color-marker-label: rgba(1, 37, 91, 1);
  --color-vessel-main: rgba(0, 193, 231, 1);
  --color-vessel-main-highlight: rgba(0, 193, 231, 0.3);
  --color-vessel-secondary: rgba(245, 158, 132, 1);
  --color-vessel-secondary-highlight: rgba(245, 158, 132, 0.5);
  --color-background: #fff;
  --color-background-highlight: rgba(255, 255, 255, 0.2);
  --color-background-map: rgba(0, 38, 92, 1);
  --color-background-gradient: linear-gradient(180deg, #e5f0f2 0%, #fff 50%);
  --color-veil: rgba(1, 37, 91, 0.8);
  --highlight: linear-gradient(
    180deg,
    transparent 60%,
    var(--color-brand-highlight) 60%,
    var(--color-brand-highlight) 90%,
    transparent 90%
  );
  --highlight-vessel-main: linear-gradient(
    180deg,
    transparent 60%,
    var(--color-vessel-main-highlight) 60%,
    var(--color-vessel-main-highlight) 90%,
    transparent 90%
  );
  --highlight-vessel-secondary: linear-gradient(
    180deg,
    transparent 60%,
    var(--color-vessel-secondary-highlight) 60%,
    var(--color-vessel-secondary-highlight) 90%,
    transparent 90%
  );
  --highlight-port: linear-gradient(
    180deg,
    transparent 60%,
    var(--color-event-port-highlight) 60%,
    var(--color-event-port-highlight) 90%,
    transparent 90%
  );

  /* SHADOWS */
  --block-shadow: 0 0 1rem 0 rgba(44, 61, 108, 0.15);
  --drop-shadow: drop-shadow(0 0.5rem 1rem rgba(44, 61, 108, 0.15));
}

html {
  font-size: 10px;
  height: 100vh;

  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

body {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-L);
  color: var(--color-brand);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  overflow-x: hidden;
}

.ReactModal__Body--open {
  overflow: hidden;
}

input {
  -webkit-appearance: none;
  background: none;
  font-family: var(--font-family);
  font-size: var(--font-size-L);
  color: var(--color-brand);
  border: var(--border);
  outline: none;
  transition: all 150ms linear;
}

input::placeholder {
  color: var(--color-brand-secondary);
}

input:focus {
  border: 1px solid var(--color-brand-secondary);
}

label {
  color: var(--color-brand-secondary);
  font-size: var(--font-size-S);
  line-height: 2rem;
  text-transform: uppercase;
  display: block;
}

button {
  font-family: var(--font-family);
  font-size: var(--font-size-S);
  line-height: 2rem;
  color: var(--color-brand);
  fill: var(--color-brand);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 150ms ease-out;
  background-repeat: no-repeat;
  background-position: center center;
}

span[aria-label],
button[aria-label] {
  position: relative;
}

span[aria-label]::before,
button[aria-label]::before {
  content: attr(aria-label);
  display: block;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 1rem);
  width: max-content;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  font-size: var(--font-size-M);
  line-height: 1.5;
  border-radius: 3px;
  background: var(--color-brand);
  color: var(--color-background);
  filter: var(--drop-shadow);
  border: 1px solid var(--color-background-highlight);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  pointer-events: none;
  transform: translate(-50%, 1rem);
}

span[aria-label]:hover::before,
button[aria-label]:hover::before {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.2s ease-in-out 0.5s;
}

@media only screen and (max-width: 720px) {
  span[aria-label]:not(.tip-mobile)::before,
  button[aria-label]:not(.tip-mobile)::before {
    display: none;
  }
}

span[data-tip-wrap='multiline']::before,
button[data-tip-wrap='multiline']::before {
  width: 32rem;
  white-space: pre-wrap;
}

span[data-tip-pos='top-left']::before,
button[data-tip-pos='top-left']::before {
  left: 0;
  transform: translate(0, 1rem);
}

span[data-tip-pos='top-left']:hover::before,
button[data-tip-pos='top-left']:hover::before {
  transform: translate(0, 0);
}

span[data-tip-pos='top-right']::before,
button[data-tip-pos='top-right']::before {
  left: initial;
  right: 0;
  transform: translate(0, 1rem);
}

span[data-tip-pos='top-right']:hover::before,
button[data-tip-pos='top-right']:hover::before {
  transform: translate(0, 0);
}

span[data-tip-pos='left']::before,
button[data-tip-pos='left']::before {
  top: 50%;
  right: calc(100% + 1rem);
  left: initial;
  bottom: initial;
  transform: translate(1rem, -50%);
}

span[data-tip-pos='left']:hover::before,
button[data-tip-pos='left']:hover::before {
  transform: translate(0, -50%);
}

span[data-tip-pos='bottom']::before,
button[data-tip-pos='bottom']::before {
  top: calc(100% + 1rem);
  left: 50%;
  bottom: initial;
  transform: translate(-50%, -1rem);
}

span[data-tip-pos='bottom']:hover::before,
button[data-tip-pos='bottom']:hover::before {
  transform: translate(-50%, 0);
}

span[data-tip-pos='bottom-left']::before,
button[data-tip-pos='bottom-left']::before {
  top: calc(100% + 1rem);
  left: 0;
  bottom: initial;
  transform: translate(0, -1rem);
}

span[data-tip-pos='bottom-left']:hover::before,
button[data-tip-pos='bottom-left']:hover::before {
  transform: translate(0, 0);
}

span[data-tip-pos='bottom-right']::before,
button[data-tip-pos='bottom-right']::before {
  top: calc(100% + 1rem);
  left: initial;
  bottom: initial;
  right: 0;
  transform: translate(0, -1rem);
}

span[data-tip-pos='bottom-right']:hover::before,
button[data-tip-pos='bottom-right']:hover::before {
  transform: translate(0, 0);
}

span[data-tip-pos='right']::before,
button[data-tip-pos='right']::before {
  top: 50%;
  left: calc(100% + 1rem);
  bottom: initial;
  transform: translate(-1rem, -50%);
}

span[data-tip-pos='right']:hover::before,
button[data-tip-pos='right']:hover::before {
  transform: translate(0, -50%);
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

.veil {
  position: absolute;
  cursor: pointer;
  background: var(--color-veil);
  opacity: 0.75;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;

  /* new - was 1px */
  margin: 0;

  /* new - was -1px */
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
