$datepicker__background-color: var(--color-background) !default;
$datepicker__border-color: var(--color-border) !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #8a9fc4 !default;
$datepicker__selected-color: #f3f5f9 !default;
$datepicker__selected-color-dark: #dce2ee !default;
$datepicker__text-color: var(--color-brand) !default;
$datepicker__header-color: var(--color-brand) !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0 !default;
$datepicker__day-margin: 0 !default;
$datepicker__font-size: 1.6rem !default;
$datepicker__font-family: 'Roboto', sans-serif !default;
$datepicker__item-size: 4rem !default;
$datepicker__margin: 1rem !default;
$datepicker__navigation-size: 0.5rem !default;
$datepicker__triangle-size: 0 !default;
