main {
  width: 100vw;
  overflow-x: hidden;
  padding-top: var(--header-height);
  min-height: 100vh;
  display: flex;
}

.menuWrapper {
  position: fixed;
  top: 0;
  z-index: 4;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
  width: 100%;
  height: 100%;
  display: flex;
}

.menuVeil {
  transition: opacity 300ms linear 300ms;
}

.modalOverlay {
  background: var(--color-veil);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 720px) {
  .modalOverlay {
    align-items: flex-start;
  }
}

.modalContentWrapper {
  padding: var(--space-M);
  padding-bottom: 8rem;
  outline: none;
  background: var(--color-background);
  width: 100%;
  max-width: 72rem;
  overflow: auto;
  max-height: 100vh;
  height: var(--vh, 1vh) * 100;
}

@media only screen and (min-width: 720px) {
  .modalContentWrapper {
    width: 90%;
    padding: var(--space-L);
    height: auto;
  }
}

.modalTitle {
  margin-top: 2rem;
}

.modalContent {
  margin-top: 1rem;
  max-height: 50vh;
  overflow: auto;
}

.modalContent h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.modalContent li,
.modalContent p {
  margin: 1rem 0;
  line-height: 2.4rem;
}

.modalContent li li {
  margin-left: 2rem;
}

.modalContent li li li {
  margin-left: 4rem;
}

.modalContentLink,
.modalContentLink:visited,
.modalContent a,
.modalContent a:visited {
  color: #fa5e5e;
}

.modalFooter {
  display: flex;
  margin-top: 3rem;
  justify-content: flex-end;
}

.modalButton {
  padding: 1rem 3rem;
  line-height: 2rem;
  text-transform: uppercase;
  border-radius: 0.3rem;
  background: var(--color-brand);
  color: var(--color-background);
  width: auto;
}

.hidden {
  transform: translateX(-100%);
}

.menuWrapper > div:first-of-type {
  background-image: url('assets/images/juan-vilata.jpg');
  background-size: cover;
  background-position: center;
}

/* .menuWrapper.hidden > div:first-of-type {
  background-image: none;
} */

.hidden .menuVeil {
  opacity: 0;
  transition: none;
}
